import React, {useState} from 'react';
import {Row, Col, Button, Input} from 'reactstrap';
import Frame from '../frame/Frame';
import Swal from 'sweetalert';
//const endpoint = 'http://localhost:5000/';
const endpoint = 'https://mail-tumejoryo-l2ffygwira-uw.a.run.app/contacto';

export default function Seccion7(props) {
  const [curso, setCurso] = useState(null);
  const [ubicacion, setUbicacion] = useState('Puebla');
  const [nombre, setNombre] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  return (
    <Frame id={props.id} className="hPage section fondo2 sinpymiz">
      <div>
        <div className="cardCursos centrado">
          <div className="pabmuygrande transparente blanco alturaCardCursos">
            <Row>
              <Col
                xs={12}
                md={{
                  size: 10,
                  offset: 1,
                }}
                xl={{
                  size: 8,
                  offset: 2,
                }}>
                <div className="paddingCursos">
                  <Row>
                    <Col xs={6} className="izquierda borderRBlanco pabmediano">
                      <div className="textoCursos letra">Cursos</div>
                      <div className="letraInscribete">
                        Inscríbete! Ofrecemos cursos en:
                      </div>
                      <Input
                        name="ubicacion"
                        type="select"
                        className="inputSelect"
                        onChange={({target}) => setUbicacion(target.value)}>
                        <option value="tipo" hidden>
                          Seleccionar ubicación
                        </option>
                        <option value="Puebla">Puebla</option>
                      </Input>
                      <div className="parmuychico">
                        <Input
                          name="curso"
                          type="select"
                          onChange={({target}) => setCurso(target.value)}
                          className="inputSelect">
                          <option value="tipo" hidden>
                            Seleccionar curso
                          </option>
                          <option value="emprendimiento">Emprendimiento</option>
                          <option value="jovenes">Jóvenes</option>
                          <option value="empresas">Empresas</option>
                          <option value="desarrollo">Desarrollo humano</option>
                        </Input>
                      </div>

                      <div className="bottom pdeinput">
                        <div className="borderRBlanco pdegrande">
                          <Input
                            name="nombre"
                            type="text"
                            placeholder="Nombre:"
                            className="inputEmail"
                            onChange={({target}) => setNombre(target.value)}
                          />
                          <Input
                            name="celular"
                            type="tel"
                            placeholder="Celular:"
                            className="inputEmail"
                            onChange={({target}) => setCelular(target.value)}
                          />
                          <Input
                            name="email"
                            type="email"
                            placeholder="Correo electrónico:"
                            className="inputEmail"
                            onChange={({target}) => setEmail(target.value)}
                          />
                          <div className="parmediano">
                            <Button
                              onClick={() => {
                                if (
                                  ubicacion &&
                                  curso &&
                                  nombre &&
                                  celular &&
                                  email
                                ) {
                                  fetch(endpoint, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                      tipo: 'cursos',
                                      ubicacion,
                                      curso,
                                      nombre,
                                      celular,
                                      email,
                                    }),
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                  })
                                    .then(() => {
                                      Swal(
                                        'Exito!',
                                        'Hemos recibido tu información, te contactaremos en breve',
                                        'success',
                                      );
                                    })
                                    .catch(() => {
                                      Swal(
                                        'Exito!',
                                        'Hemos recibido tu información, te contactaremos en breve',
                                        'success',
                                      );
                                    });
                                }
                              }}
                              className="botonNegro claseBoton3 pladosgrande wnormal tboton">
                              Solicitar información
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="izquierda pdegrande pabmediano">
                      <div className="textoCursos letra">Capacitadores</div>
                      <div className="letraInscribete justificado">
                        Quieres unirte a nuestro equipo y ganar más de 1,000
                        dólares mensuales
                      </div>
                      <Input
                        name="ubicacion"
                        type="select"
                        onChange={({target}) => setUbicacion(target.value)}
                        className="inputSelect">
                        <option value="tipo" hidden>
                          Seleccionar ubicación
                        </option>
                        <option value="Puebla">Puebla</option>
                      </Input>
                      <div className="bottom">
                        <div className="pdegrande">
                          <Input
                            name="nombre"
                            type="text"
                            placeholder="Nombre:"
                            onChange={({target}) => setNombre(target.value)}
                            className="inputEmail"
                          />
                          <Input
                            name="celular"
                            type="tel"
                            placeholder="Celular:"
                            onChange={({target}) => setCelular(target.value)}
                            className="inputEmail"
                          />
                          <Input
                            name="email"
                            type="email"
                            placeholder="Correo electrónico:"
                            onChange={({target}) => setEmail(target.value)}
                            className="inputEmail"
                          />
                          <div className="parmediano">
                            <Button
                              onClick={() => {
                                if (ubicacion && nombre && celular && email) {
                                  fetch(endpoint, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                      tipo: 'capacitador',
                                      ubicacion,
                                      nombre,
                                      celular,
                                      email,
                                    }),
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                  })
                                    .then(() => {
                                      Swal(
                                        'Exito!',
                                        'Hemos recibido tu información, te contactaremos en breve',
                                        'success',
                                      );
                                    })
                                    .catch(() => {
                                      Swal(
                                        'Exito!',
                                        'Hemos recibido tu información, te contactaremos en breve',
                                        'success',
                                      );
                                    });
                                }
                              }}
                              className="botonNegro claseBoton3 pladosgrande wnormal tboton">
                              Solicitar información
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Frame>
  );
}
