import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";

class Seccion1 extends PureComponent {
	render() {
		return (
			<Frame
				id={this.props.id}
				clase="botonBlanco3"
				className="hPage section fondo fondoBlanco sinpymiz"
			>
				<div className="fixedTop">
					<img
						src={
							"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/logoAzul.svg"
						}
						className="cristinalogo"
						alt="logo"
					/>
				</div>
				<section id="cardInicio">
					<div className="pabmuygrande transparente blanco alturaCardInicio">
						<Row>
							<Col
								xs={12}
								md={{ size: 10, offset: 1 }}
								xl={{ size: 8, offset: 2 }}
							>
								<div className="paddingInicio">
									<span className="pabchico textoInicio mayus centro letra">
										Genera tu propia riqueza emocional y
										econ
										<abbr className="claseom">ó</abbr>
										mica
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</section>
			</Frame>
		);
	}
}

export default Seccion1;
