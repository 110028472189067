import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";
import Emprendimiento from "./Emprendimiento";

class Seccion2chica1 extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false
	};

	render() {
		return (
			<React.Fragment>
				<Frame
					id={this.props.id}
					className="d-block d-sm-none section hPage fondoNaranja"
					clase="botonAmarillo"
					padding
				>
					<section className="paddingSeccion gris h100">
						<Row className="h100">
							<Col
								xs={12}
								className="paddingEmpresas fondoNaranja blanco"
							>
								<Emprendimiento />
							</Col>
						</Row>
					</section>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion2chica1;
