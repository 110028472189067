import React, { PureComponent } from "react";
import AreaEmpresas from "./AreaEmpresas";

class Emprendimiento extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false
	};

	render() {
		return (
			<React.Fragment>
				<AreaEmpresas
					titulo="Emprendimiento"
					video
					subtitulo="Genera o potencializa tu idea de
										negocio!"
					subsubtitulo="Diplomado que combina negocios y bienestar subjetivo"
					bullets={
						<ul className="pizmediano">
							<li className="parmuychico">
								Incrementa tu riqueza emocional y económica
							</li>
							<li className="parmuychico">
								Modelo de negocios - CANVAS
							</li>
							<li className="parmuychico">
								Asegura el desempeño y la rentabilidad de tu
								idea de negocio
							</li>
							<li className="parmuychico">
								Herramientas financieras, personales y
								empresariales
							</li>
							<li className="parmuychico">
								Guía de bienestar y emociones positivas
							</li>
						</ul>
					}
				/>
			</React.Fragment>
		);
	}
}

export default Emprendimiento;
