import React, { PureComponent } from "react";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Row,
	Col
} from "reactstrap";
import logo from "../imgs/logoAzul.svg";

class Cuestionario extends PureComponent {
	state = {
		ver: false,
		activeTab: "1"
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	}

	ver() {
		this.setState({ ver: !this.state.ver });
	}

	render() {
		return (
			<React.Fragment>
				<Row className="parenorme">
					<Col
						xs={{ size: 10, offset: 1 }}
						md={{ size: 8, offset: 2 }}
					>
						<div className="pgrande centro">
							<img
								src={logo}
								alt="logo"
								style={{ height: 130 }}
							/>
						</div>
						<div className="tmuygrande wbold centro">
							Cuestionario basado en la Encuesta Nacional de
							Gastos de los Hogares en Gasto del INEGI
						</div>{" "}
						<div className="wbold justificado parmuychico">
							El presente cuestionario es para medir el impacto
							del “MODELO” en las personas participantes en los
							niveles de bienestar subjetivo, salud, economía y
							emprendimiento, por lo que agradecemos su valiosa
							participación reiterándole que la información será
							manejada con ABSOLUTA CONFIDENCIALIDAD y sólo se
							utilizará con fines estadísticos.
						</div>
					</Col>
				</Row>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={
								this.state.activeTab === "1" ? "active" : ""
							}
							onClick={() => {
								this.toggle("1");
							}}
						>
							Entrada
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={
								this.state.activeTab === "2" ? "active" : ""
							}
							onClick={() => {
								this.toggle("2");
							}}
						>
							Salida
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="1">
						<Row>
							<Col className="centro parmuygrande">
								<iframe
									src="https://docs.google.com/forms/d/e/1FAIpQLSeunDrEvA0uFSvUN9tlcE_pYPe5Wg4iFhIz8xB-uNQmHPrLeA/viewform?embedded=true"
									width="640"
									height="9898"
									frameborder="0"
									marginheight="0"
									marginwidth="0"
								>
									Cargando…
								</iframe>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						<Row>
							<Col className="centro parmuygrande">
								<iframe
									src="https://docs.google.com/forms/d/e/1FAIpQLSfgFwgUHxdlOtMPWzCLVaMc1qjtXuG55cUPalxM97jT4M1uug/viewform?embedded=true"
									width="640"
									height="9898"
									frameborder="0"
									marginheight="0"
									marginwidth="0"
								>
									Cargando…
								</iframe>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			</React.Fragment>
		);
	}
}

export default Cuestionario;
