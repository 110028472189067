import React from "react";
import { Switch, Route } from "react-router-dom";
import { Inicio, Error404, Cuestionario } from "./components";

class App extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={Inicio} />
				<Route exact path="/cuestionario" component={Cuestionario} />
				<Route render={props => <Error404 />} />
			</Switch>
		);
	}
}

export default App;
