import React, { PureComponent } from "react";
import { NavLink, Nav, NavItem, NavbarToggler, Collapse } from "reactstrap";
import Menu from "../Menu";
import Seccion1 from "./seccion1Inicio/Seccion1";
import Seccion2 from "./seccion2Empresas/Seccion2";
import Seccion2chica1 from "./seccion2Empresas/Seccion2chica1";
import Seccion2chica2 from "./seccion2Empresas/Seccion2chica2";
import Seccion3 from "./seccion3Experiencia/Seccion3";
import Seccion4 from "./seccion4Jovenes/Seccion4";
import Seccion5 from "./seccion5DesarrolloHumano/Seccion5";
import Seccion6 from "./seccion6Quienes/Seccion6";
import Seccion7 from "./seccion7Cursos/Seccion7";
import Seccion8 from "./seccion8Contacto/Seccion8";
import Seccion9 from "./seccion9ClubEmprendedor/Seccion9";
import ReactFullpage from "@fullpage/react-fullpage";
import anime from "animejs/lib/anime.es.js";

class Inicio extends PureComponent {
	constructor(props) {
		super(props);
		const showModal = localStorage.getItem("showModal");
		this.state = {
			toggle: false,
			menu: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 1);
	}

	menu() {
		this.setState({
			menu: !this.state.menu
		});
	}

	sweepIn(origin, destination, direction) {
		if (destination.index === 0) {
			anime({
				targets: ".fadeIn2",
				opacity: 1,
				scale: 1.2,
				duration: 800
			});

			anime({
				targets: ".fadeIn3",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 800
			});

			anime({
				targets: ".fadeIn4",
				opacity: 1,
				scale: 1.2,
				delay: 1600
			});

			anime({
				targets: ".fadeIn5",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 2400
			});

			anime({
				targets: ".fadeIn6",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 3000
			});
		}
	}
	rutas = [
		{
			name: "Empresas",
			path: "/#empresas"
		},
		{
			name: "Emprendimiento",
			path: "#emprendimiento"
		}
	];

	render() {
		return (
			<React.Fragment>
				<ReactFullpage
					licenseKey="23D069B2-6B424173-ADAD6D62-6CB56498"
					controlArrows={true}
					anchors={
						window.matchMedia("(min-width: 576px)").matches
							? [
									"inicio",
									"empresas-y-emprendimiento",
									"experiencia",
									"jovenes",
									"club-emprendedor",
									"desarrollo-humano",
									"quienes-somos",
									"cursos-y-capacitadores",
									"contacto"
							  ]
							: [
									"inicio",
									"emprendimiento",
									"empresas",
									"experiencia",
									"jovenes",
									"club-emprendedor",
									"desarrollo-humano",
									"quienes-somos",
									"cursos-y-capacitadores",
									"contacto"
							  ]
					}
					slideSelector=".fpage-slide"
					afterLoad={this.sweepIn}
					keyboardScrolling={true}
					menu="#navnav"
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<Menu
									color={`azul fondoBlancoNav ${!this.state
										.menu && "fondoBlancoNav2"}`}
									fixed="top"
									inicio
								>
									<NavbarToggler
										onClick={this.menu.bind(this)}
									/>
									<Collapse isOpen={this.state.menu} navbar>
										<Nav
											className="ml-auto"
											navbar
											id="navnav "
										>
											<NavItem
												className="pdechico d-block d-sm-none inicioEmpresas"
												data-menuanchor="empresas-y-emprendimiento"
											>
												<NavLink
													className="linkInicio mayus"
													href="#empresas"
												>
													empresas
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico d-block d-sm-none"
												data-menuanchor="empresas-y-emprendimiento"
											>
												<NavLink
													className="linkInicio mayus"
													href="#emprendimiento"
												>
													emprendimiento
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico d-none d-sm-block inicioEmprendimiento"
												data-menuanchor="empresas-y-emprendimiento"
											>
												<NavLink
													className="linkInicio mayus"
													href="#empresas-y-emprendimiento"
												>
													empresas y emprendimiento
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico inicioNavbar"
												data-menuanchor="jovenes"
											>
												<NavLink
													className="linkInicio mayus"
													href="#jovenes"
												>
													jóvenes
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico inicioNavbar"
												data-menuanchor="desarrollo-humano"
											>
												<NavLink
													className="linkInicio mayus d-block d-md-none"
													href="#desarrollo-humano"
												>
													desarrollo humano
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico inicioNavbar"
												data-menuanchor="quienes-somos"
											>
												<NavLink
													className="linkInicio mayus d-none d-md-block"
													href="#quienes-somos"
												>
													quiénes somos
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico inicioNavbar"
												data-menuanchor="cursos-y-capacitadores"
											>
												<NavLink
													className="linkInicio mayus"
													href="#cursos-y-capacitadores"
												>
													<span className="d-block d-xl-none">
														cursos y capacitadores
													</span>
													<span className="d-none d-xl-block">
														cursos
													</span>
												</NavLink>
											</NavItem>

											<NavItem
												className="inicioNavbar"
												data-menuanchor="contacto"
											>
												<NavLink
													className="navHighlight"
													href="cuestionario"
												>
													Cuestionarios
												</NavLink>
											</NavItem>

											<NavItem className="pdechico inicioNavbar">
												<NavLink
													className="linkInicio mayus"
													href="https://blog.tumejoryo.mx/"
												>
													Blog
												</NavLink>
											</NavItem>
											<NavItem
												className="pdechico inicioNavbar"
												data-menuanchor="contacto"
											>
												<NavLink
													className="linkInicio mayus"
													href="#contacto"
												>
													Contacto
												</NavLink>
											</NavItem>
										</Nav>
									</Collapse>
								</Menu>
								<Seccion1 fullpageApi={fullpageApi} />
								{window.matchMedia("(min-width: 576px)")
									.matches && <Seccion2 />}
								{!window.matchMedia("(min-width: 576px)")
									.matches && (
									<React.Fragment>
										<Seccion2chica1 />
										<Seccion2chica2 />
									</React.Fragment>
								)}
								<Seccion3 />
								<Seccion4 />
								<Seccion9 />
								<Seccion5 />
								<Seccion6 />
								<Seccion7 />
								<Seccion8 />
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</React.Fragment>
		);
	}
}

export default Inicio;
