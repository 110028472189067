import React, { PureComponent } from "react";
import BotonSolicitarInformacion from "../../objetos/BotonSolicitarInformacion";

class AreaEmpresas extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false
	};

	render() {
		return (
			<React.Fragment>
				<div className="paddingEmpresas">
					<div className="letraEmpresas letra izquierda centro">
						{this.props.titulo}
					</div>
					<div className="d-sm-none d-md-block parmediano">
						{this.props.img && (
							<img
								src={this.props.img}
								className="imagenEmpresas"
							/>
						)}
						{this.props.video && (
							<video
								className="imagenEmpresas"
								poster="https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/4.png"
								allowfullscreen
								controls
								playsinline
							>
								<source
									src={
										"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/tumejor.mp4"
									}
									type="video/mp4"
								/>
							</video>
						)}
					</div>
					<div className="letra letraSubtitulo paddingSubtitulo">
						{this.props.subtitulo}
					</div>
					<div className="letraSubtitulo paddingSubSubtitulo">
						{this.props.subsubtitulo}
					</div>
					<React.Fragment>
						<div className="letraBullets izquierda paddingBullets">
							{this.props.bullets}
						</div>
					</React.Fragment>
					<BotonSolicitarInformacion />
				</div>
			</React.Fragment>
		);
	}
}

export default AreaEmpresas;
