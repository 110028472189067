import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";
import Empresas from "./Empresas";
import Emprendimiento from "./Emprendimiento";

class Seccion2 extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false
	};

	render() {
		return (
			<React.Fragment>
				<Frame
					id={this.props.id}
					className="fondoBlanco hPage d-none d-sm-block section"
					padding
				>
					<Row className="h100">
						<Col
							sm={6}
							className="paddingEmpresas fondoNaranja blanco"
						>
							<Emprendimiento />
						</Col>
						<Col
							sm={6}
							className="paddingEmpresas fondoRojo blanco"
						>
							<Empresas />
						</Col>
					</Row>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion2;
