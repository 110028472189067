import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";

class Seccion3 extends PureComponent {
	state = {
		aceptado: false
	};

	render() {
		return (
			<React.Fragment>
				<Frame id={this.props.id} className="fondoBlanco hpage section">
					<div className="centro">
						<div className="rojo letra letraContamos">
							CONTAMOS CON MÁS DE 20 AÑOS DE EXPERIENCIA
						</div>
						<div className="letraAsesorando parmuychico gris">
							CON MAS DE 30,000 PERSONAS CAPACITADAS Y 10 MIL
							HORAS DE CAPACITACION IMPARTIDA
						</div>
					</div>
					<Row className="pargrande">
						<Col
							xs={12}
							lg={{ size: 10, offset: 1 }}
							xl={{ size: 8, offset: 2 }}
						>
							<Row>
								<Col xs={4} md={3} xl={2} className="centro">
									<img
										className="claseLogotipos2"
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/volkswagen.png"
										}
										alt="volkswagen"
									/>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div className="parenorme">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/seat.png"
											}
											alt="seat"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "43px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/hsbc.png"
											}
											alt="hsbc"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/moctezuma.png"
											}
											alt="moctezuma"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "36px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/johnson.jpg"
											}
											alt="johnson"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "17px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/santander.jpg"
											}
											alt="santander"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div className="parmuygrande">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/audi.png"
											}
											alt="audi"
										/>
									</div>
								</Col>{" "}
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/coca.png"
											}
											alt="coca"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/bancomer.png"
											}
											alt="bancomer"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div className="pargrande">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/banco-uno.jpg"
											}
											alt="banco"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/universidad.png"
											}
											alt="universidad"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/montes.png"
											}
											alt="montes"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "15px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/nexe.jpg"
											}
											alt="nexe"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/herradura.png"
											}
											alt="herradura"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/ibero.png"
											}
											alt="ibero"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "37px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/nacional.jpg"
											}
											alt="nacional"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/sep.jpg"
											}
											alt="sep"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "44px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/secretaria.jpeg"
											}
											alt="secretaria"
										/>
									</div>
								</Col>
								<Col xs={4} md={3} xl={2} className="centro">
									<div style={{ paddingTop: "30px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/tec.jpg"
											}
											alt="tec"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div className="pargrande">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/iansa.jpeg"
											}
											alt="iansa"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<div className="pargrande">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/imbera.png"
											}
											alt="imbera"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<div style={{ paddingTop: "40px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/lufussa.png"
											}
											alt="lufussa"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<div className="parmuychico">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/human.jpg"
											}
											alt="human"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<img
										className="claseLogotipos3"
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/bal.png"
										}
										alt="bal"
									/>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<div className="pargrande">
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/AES-logo.png"
											}
											alt="aes"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-xl-block"
								>
									<div style={{ paddingTop: "27px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/dehac.jpg"
											}
											alt="dehac"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div style={{ paddingTop: "27px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/cinco.jpg"
											}
											alt="cinco"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div style={{ paddingTop: "2px" }}>
										<img
											className="claseLogotipos3"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/iteso.jpg"
											}
											alt="iteso"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div style={{ paddingTop: "8px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/panamericana.png"
											}
											alt="panamericana"
										/>
									</div>
								</Col>
								<Col
									xs={4}
									md={3}
									xl={2}
									className="centro d-none d-md-block"
								>
									<div style={{ paddingTop: "27px" }}>
										<img
											className="claseLogotipos"
											src={
												"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/jalisco.png"
											}
											alt="jalisco"
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion3;
