import React, { PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Navbar, NavbarBrand } from "reactstrap";
import "./Menu.css";

class Menu extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <Navbar
          light
          expand="xl"
          fixed={"top"}
          className={`${this.props.color}`}
        >
          <NavbarBrand className="d-block d-xl-none">
            <img
              className="cristinalogo2"
              src={
                "https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/logoAzul.svg"
              }
              alt="logo de cristina tu mejor yo"
            />
          </NavbarBrand>
          {this.props.children}
        </Navbar>
      </React.Fragment>
    );
  }
}
export default withRouter(Menu);
