import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";
import BotonSolicitarInformacion from "../../objetos/BotonSolicitarInformacion";

class Seccion4 extends PureComponent {
	state = {
		aceptado: false
	};

	render() {
		return (
			<React.Fragment>
				<Frame id={this.props.id} className="fondoAqua hpage section">
					<div className="paddingTodoDesarrollo">
						<Row className="paddingFotoDesarrollo">
							{/*chica*/}
							<Col
								xs={12}
								className="d-block d-sm-none sinpymlados"
							>
								<div className="letra letraDesarrollo2 azul">
									Jóvenes
								</div>
								<div className="ppasion">
									<Row>
										<Col className="sinpymde">
											<div className="fondoBlanco pchico tprofesion azul letra centro">
												Tu pasión tu profesión
											</div>
										</Col>
										<Col className="blanco letraEmprendedor sinpymde parmuychico">
											Jóvenes de 16 a 19 años
										</Col>
									</Row>
								</div>
								<video
									className="fotoDesarrollo2"
									poster="https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/3.png"
									allowfullscreen
									controls
									playsinline
								>
									<source
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/jovenes.mp4"
										}
										type="video/mp4"
									/>
								</video>
							</Col>
							{/*fin chica*/}
							{/*grande*/}
							<Col
								sm={6}
								className="d-none d-sm-block columnaIzquierda"
							>
								<video
									className="fotoJovenes"
									poster="https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/3.png"
									allowfullscreen
									controls
									playsinline
								>
									<source
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/jovenes.mp4"
										}
										type="video/mp4"
									/>
								</video>
								<div className="parenorme">
									<img
										className="fotoJovenes"
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/risas.jpeg"
										}
										alt="foto de jovenes"
									/>
								</div>
							</Col>
							{/*fin grande*/}
							{/*COlumna 2*/}
							<Col
								xs={12}
								sm={6}
								className="blanco paddingJovenes"
							>
								{/*grande*/}
								<div className="d-none d-sm-block">
									<div className="letra letraDesarrollo3 azul">
										Jóvenes
									</div>
									<Row>
										<Col xs={5} className="sinpymiz">
											<div className="fondoBlanco pchico tprofesion azul letra centro">
												Tu pasión tu profesión
											</div>
										</Col>
										<Col
											xs={7}
											className="blanco letraEmprendedor sinpymde"
										>
											<div className="pizmuygrande parmicro2">
												Jóvenes de 16 a 19 años
											</div>
										</Col>
									</Row>
								</div>
								{/*fin grande*/}
								<div className="blanco letra letraEmprendedor">
									Guía para decidir carrera desde tus talentos
									y lo que te hace feliz
								</div>
								<div className="letraBulletsEmprendedor izquierda paddingBullets2 paddingBulletsJovenes blanco">
									<ul className="pizmediano">
										<li className="parmuychico">
											Personalidad y fortalezas
										</li>
										<li className="parmuychico">
											Finanzas personales
										</li>
										<li className="parmuychico">
											Opciones de carrera
										</li>
									</ul>
								</div>
								{/*separacion de ambas secciones*/}
								{/*chica*/}
								<div className="parmicro pabmediano d-block d-sm-none">
									<div className="lmuychica fondoBlanco" />
								</div>
								{/*fin chica*/}
								{/*grande*/}
								<div className="d-none d-sm-block paddingMision">
									<Row>
										<Col xs={5} className="sinpymiz">
											<div className="fondoBlanco pchico tprofesion azul letra centro">
												Tu misión tu vocación
											</div>
										</Col>
										<Col
											xs={7}
											className="blanco letraEmprendedor sinpymde"
										>
											<div className="pizmuygrande parmicro2">
												Jóvenes de 20 a 25 años
											</div>
										</Col>
									</Row>
								</div>
								{/*fin grande*/}
								{/*chica*/}
								<div className="d-block d-sm-none pjovenes">
									<Row>
										<Col className="sinpymlados">
											<div className="fondoBlanco pchico tprofesion azul letra centro">
												Tu misión tu vocación
											</div>
										</Col>
										<Col className="blanco letraEmprendedor sinpymde parmuychico">
											Jóvenes de 20 a 25 años
										</Col>
									</Row>
								</div>
								{/*fin chica*/}
								<div className="blanco letra letraEmprendedor">
									Genera ingreso desde lo que sí tienes
								</div>
								<div className="letraBulletsEmprendedor izquierda paddingBullets2 paddingBulletsJovenes blanco">
									<ul className="pizmediano">
										<li className="parmuychico">
											Mapa de habilidades y conocimientos
										</li>
										<li className="parmuychico">
											Descubre tu misión de vida
										</li>
										<li className="parmuychico">
											Genera riqueza emocional y económica
										</li>
									</ul>
								</div>
								<BotonSolicitarInformacion boton2 />
							</Col>
						</Row>
					</div>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion4;
