import React, { PureComponent } from "react";
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Frame from "../frame/Frame";

class Seccion6 extends PureComponent {
	state = {
		ver: false
	};

	ver() {
		this.setState({ ver: !this.state.ver });
	}

	render() {
		return (
			<React.Fragment>
				<Frame
					id={this.props.id}
					className="fondoGrisClaro4 hpage section"
				>
					<div className="paddingTodoDesarrollo">
						<Row className="paddingFotoDesarrollo">
							<Col xs={12} sm={6}>
								<img
									className="fotoCristina"
									src={
										"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/11.jpg"
									}
									alt=""
								/>
							</Col>
							<Col
								xs={12}
								sm={6}
								className="blanco paddingDesarrollo"
							>
								<div className="letra letraDesarrollo azul">
									Cristina Reinosa
								</div>
								<div className="letraAprende azul">
									Business & life coach
								</div>
								<div className="letraBullets izquierda paddingBullets negro pdemuygrande justificado">
									Fundadora y co-creadora del primer modelo de
									emprendimiento en México desde la ciencia
									del bienestar subjetivo "Tu Mejor Yo" con
									más de mil egresados. Asesora de proyectos
									con empresas publicas y privadas a nivel
									nacional e internacional ademas de gobiernos
									federales, estatales y municipales. Asi como
									participante activa de consejos consultivos
									de diversas asociaciones y o ONGs.
								</div>
								<div className="parmuychico">
									<Button
										onClick={this.ver.bind(this)}
										className="botonAzul claseBoton3"
									>
										<div className="pladoschico">
											Ver más
										</div>
									</Button>
								</div>
								<Modal
									isOpen={this.state.ver}
									toggle={this.ver.bind(this)}
								>
									<ModalBody>
										<div className="wbold tgrande azul parmediano">
											Business
										</div>
										<div>
											<label className="wbold">
												Especialidad en temas
												empresariales:
											</label>{" "}
											plan estratégico y comercial,
											relaciones públicas, ventas,
											emprendimiento, marketing,
											reconfiguracion financiera, planes y
											simuladores de negocios, formación
											de instructores.
										</div>
										<div className="wbold tgrande azul parmediano">
											Life
										</div>
										<div>
											<label className="wbold">
												Especialidad en el tema de
												desarrollo humano:
											</label>{" "}
											Certificada como psicoterapeuta
											humanista, psicología positiva,
											coaching ontológico, PNL, felicidad
											en el trabajo en instituciones de
											prestigio nacionales e
											internacionales.
										</div>
									</ModalBody>
									<ModalFooter>
										<Button
											onClick={this.ver.bind(this)}
											className="claseBoton3 botonRojo"
										>
											<div className="pladoschico">
												Cerrar
											</div>
										</Button>
									</ModalFooter>
								</Modal>
							</Col>
						</Row>
					</div>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion6;
