import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Frame from "../frame/Frame";
import BotonSolicitarInformacion from "../../objetos/BotonSolicitarInformacion";

class Seccion9 extends PureComponent {
	state = {
		aceptado: false
	};

	render() {
		return (
			<React.Fragment>
				<Frame
					id={this.props.id}
					className="fondoAmarillo hpage section"
				>
					<div className="paddingTodoDesarrollo">
						<Row className="paddingFotoDesarrollo">
							{/*chica*/}
							<Col
								xs={12}
								className="d-block d-sm-none sinpymlados"
							>
								<div className="letra letraClub azul">
									Club Emprendedor
								</div>
								<div className="ppasion">
									<div className="azul letraEmprendedor sinpymiz d-block d-sm-none">
										Jóvenes de 12 a 15 años
									</div>
								</div>
								<img
									className="fotoDesarrollo2"
									src={
										"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/club.jpeg"
									}
									alt="foto de jovenes"
								/>
							</Col>
							{/*fin chica*/}
							{/*grande*/}
							<Col
								sm={6}
								className="columnaIzquierda d-none d-sm-block"
							>
								<div className="parenorme">
									<img
										className="fotoDesarrollo3"
										src={
											"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/club.jpeg"
										}
										alt="foto de jovenes"
									/>
								</div>
							</Col>
							{/*fin grande*/}
							{/*COlumna 2*/}
							<Col
								xs={12}
								sm={6}
								className="blanco paddingDesarrollo2"
							>
								{/*grande*/}
								<div className="d-none d-sm-block">
									<div className="letra letraDesarrollo3 azul paddingClub">
										Club Emprendedor
										<div className="azul letraEmprendedor">
											Jóvenes de 12 a 15 años
										</div>
									</div>

									<Row>
										<Col xs={5} xl={4} className="sinpymiz">
											<div className="fondoBlanco pchico trally azul letra centro">
												Rally
											</div>
										</Col>
										<Col className="azul sinpymlados">
											<BotonSolicitarInformacion boton3 />
										</Col>
									</Row>
									<div className="azul letraEmprendedor">
										Talleres y campamentos de fin de semana
									</div>
								</div>
								{/*fin grande*/}

								{/*chica*/}
								<div className="d-block d-sm-none pjovenes pabmuychico">
									<Row className="parchico">
										<Col
											xs={3}
											className="sinpymlados parmicro"
										>
											<div className="fondoBlanco pchico trally azul letra centro">
												Rally
											</div>
										</Col>
										<Col className="azul letraEmprendedor sinpymlados parmuychico">
											<BotonSolicitarInformacion boton2 />
										</Col>
									</Row>
									<div className="azul letraEmprendedor sinpymiz">
										Talleres y campamentos de fin de semana
									</div>
								</div>
								{/*fin chica*/}
								<div className="azul letra letraEmprendedor">
									Genera ideas, descubre y aprende a través
									del juego:
								</div>
								<div className="letraBulletsEmprendedor izquierda paddingBullets2 paddingBulletsJovenes azul">
									<ul className="pizmediano">
										<li className="parmuychico">
											Talentos, recursos, ingresos y
											emociones
										</li>
									</ul>
								</div>
								{/*chica*/}
								<div className="parmicro pabmediano d-block d-sm-none">
									<div className="lmuychica fondoAzul" />
								</div>
								{/*fin chica*/}
								{/*grande*/}
								<div className="d-none d-sm-block paddingTaller">
									<Row>
										<Col xs={5} xl={4} className="sinpymiz">
											<div className="fondoBlanco pchico trally azul letra centro">
												Taller
											</div>
										</Col>
										<Col className="azul sinpymlados">
											<BotonSolicitarInformacion boton3 />
										</Col>
									</Row>
								</div>
								{/*fin grande*/}
								{/*chica*/}
								<div className="d-block d-sm-none pjovenes">
									<Row>
										<Col
											xs={3}
											className="sinpymlados parmicro"
										>
											<div className="fondoBlanco pchico trally azul letra centro">
												Taller
											</div>
										</Col>
										<Col className="azul letraEmprendedor sinpymlados parmuychico">
											<BotonSolicitarInformacion boton2 />
										</Col>
									</Row>
								</div>
								{/*fin chica*/}
								<div className="azul letra letraEmprendedor">
									Planea acciones, define metas y evalúa tu
									desempeño
								</div>
								<div className="letraBulletsEmprendedor izquierda paddingBullets2 paddingBulletsJovenes azul">
									<ul className="pizmediano">
										<li className="parmuychico">
											Personalidad y habilidades
										</li>
										<li className="parmuychico">
											Gana dinero, invierte y ahorra
										</li>
										<li className="parmuychico">
											Compite en simulador de negocios
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion9;
