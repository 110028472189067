import React, { Component } from "react";
import { Button } from "reactstrap";

class BotonSolicitarInformacion extends Component {
	state = {
		boton: false
	};
	boton() {
		this.setState({ boton: !this.state.boton });
	}
	render() {
		return (
			<React.Fragment>
				{!this.props.boton3 && (
					<React.Fragment>
						{!this.props.boton2 && (
							<Button
								href="#cursos-y-capacitadores"
								onClick={this.boton.bind(this)}
								className="botonAzul mayus tgrande wnormal claseBoton3 pizchico pdechico parmuychico"
							>
								<div className={this.props.tamano}>
									Solicitar información
								</div>
							</Button>
						)}
						{this.props.boton2 && (
							<Button
								href="#cursos-y-capacitadores"
								onClick={this.boton.bind(this)}
								className="botonAzul mayus tchica2 wnormal claseBoton3 pladosmuychico parmuychico"
							>
								<div className={this.props.tamano}>
									Solicitar información
								</div>
							</Button>
						)}
					</React.Fragment>
				)}
				{this.props.boton3 && (
					<div className="parmicro2">
						<Button
							href="#cursos-y-capacitadores"
							onClick={this.boton.bind(this)}
							className="botonAzul mayus tchica2 wnormal claseBoton3 pladosmuychico parmuychico"
						>
							<div>Solicitar información</div>
						</Button>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default BotonSolicitarInformacion;
