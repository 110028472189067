import React, {PureComponent} from 'react';
import {Row, Col} from 'reactstrap';
import Frame from '../frame/Frame';

class Seccion8 extends PureComponent {
  render() {
    return (
      <Frame id={this.props.id} className="hPage section fondoBlanco sinpymiz">
        <div className="paddingContacto">
          <Row>
            <Col xs={12} md={7} className="azul tletraContacto letra pabgrande">
              Reinventa tu vida emocional y genera más recursos económicos desde
              tus talentos, sentido de vida y aquello que te hace feliz!
            </Col>
            <Col xs={12} md={5} className="paddingContacto2">
              <div className="tcontacto">
                <div>
                  <div className="d-inline icon-telefono azul" />
                  <div className="gris d-inline pizchico">
                    +52 (222) 136 0302
                  </div>
                </div>
                <div>
                  <div className="d-inline icon-mensaje azul" />
                  <div className="gris d-inline pizchico">
                    info@tumejoryo.mx
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Frame>
    );
  }
}

export default Seccion8;
