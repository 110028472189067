import React, { PureComponent } from "react";
import {
	Row,
	Col,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Container
} from "reactstrap";
import Frame from "../frame/Frame";
import BotonSolicitarInformacion from "../../objetos/BotonSolicitarInformacion";

class Seccion5 extends PureComponent {
	state = {
		info: false
	};

	info() {
		this.setState({
			info: !this.state.info
		});
	}
	render() {
		return (
			<Frame id={this.props.id} className="hPage section fondoRosa">
				<div className="paddingTodoDesarrollo">
					<Row className="paddingFotoDesarrollo">
						<Col xs={12} sm={6}>
							<img
								className="fotoDesarrollo"
								src={
									"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/2.jpg"
								}
								alt=""
							/>
						</Col>
						<Col
							xs={12}
							sm={6}
							className="blanco paddingDesarrollo"
						>
							<div className="letra letraDesarrollo">
								Desarrollo humano
							</div>
							<div className="letra letraAprende">
								Descubre la mejor versión de ti mismo!
							</div>
							<div className="letraAprende">
								Talleres y sesiones individuales
							</div>
							<div className="letraBullets izquierda paddingBullets blanco">
								<ul className="pizmediano">
									<li className="parmuychico">
										Descubre como abrir posibilidades a
										traves de conversaciones, emociones y
										expresion corporal
									</li>
									<li className="parmuychico">
										Conoce herramientas que mejoran tus
										indices de felicidad
									</li>
									<li className="parmuychico">
										Reinventa tu vida después de una perdida
										emocional
									</li>
									<li className="parmuychico">
										Acompañamiento emocional, legal y
										terapéutico para personas en proceso de
										separación
									</li>
								</ul>
								<div className="centroDesarrolloHumano pabmuychico">
									<Button
										onClick={this.info.bind(this)}
										className="wold botonBlanco claseBoton2"
									>
										<div className="rosa parmuychico pladosmuychico">
											Ver más información
										</div>
									</Button>
								</div>
								<Modal
									isOpen={this.state.info}
									toggle={this.info.bind(this)}
								>
									<div className="fondoRosa blanco wbold tgrande pmediano">
										<div className="pizmediano parmuychico">
											Información sobre Desarrollo Humano
										</div>
									</div>
									<ModalBody>
										<div className="my-custom-scrollbar table-wrapper-scroll-y">
											<div>
												<div className="wbold azul">
													Coaching Ontológico
												</div>{" "}
												<div className="tchica2">
													Descubre tu mejor yo a
													través de abrir
													posibilidades en tus
													relaciones personales,
													familiares o laborales desde
													tu cuerpo, tus emociones y
													tu lenguaje.
												</div>
											</div>{" "}
											<div className="wbold rosa parchico">
												Psicología Positiva
											</div>
											<div className="tchica2">
												Ciencia psicológica que se
												centra en lo mejor de la vida,
												todo aquello que hace que la
												vida valga la pena vivirla, se
												propone promover los factores
												que le permiten a los individuos
												y las comunidades vivir en
												plenitud. Temas de algunos
												cursos:
											</div>
											<Container>
												<Row>
													<Col className="sinpymlados">
														<ul className="tchica3">
															<li>Felicidad</li>
															<li>
																Emociones
																Positivas{" "}
															</li>
															<li>
																Salud Positiva
															</li>
															<li>Resiliencia</li>
															<li>
																Perdón y
																Gratitud
															</li>
															<li>
																Optimismo y
																esperanza
															</li>
															<li>
																Fortalezas del
																carácter
															</li>
														</ul>
													</Col>
													<Col className="sinpymlados">
														<ul className="tchica3">
															<li>
																Florecimiento
																Humano
															</li>
															<li>
																Manejo del
																tiempo
															</li>
															<li>
																Envejecer bien
															</li>
															<li>
																Relaciones
																interpersonales
															</li>
															<li>
																Comunicación
															</li>
															<li>
																Sentido del
																humor
															</li>
															<li>
																Metas y plan de
																vida
															</li>
														</ul>
													</Col>
												</Row>
											</Container>
											<div className="wbold aqua">
												Grupos de encuentro
											</div>{" "}
											<div className="tchica2">
												Sesiones grupales para hombres y
												mujeres en reconstrucción
												después de una perdida o ruptura
												emocional.
											</div>
										</div>
									</ModalBody>
									<ModalFooter className="parmicro pabmicro pdemediano">
										<Button
											className="botonTransparente"
											onClick={this.info.bind(this)}
										>
											<BotonSolicitarInformacion tamano="tchica2" />
										</Button>
										<Button
											onClick={this.info.bind(this)}
											className="botonRojo claseBoton3 pizchico pdechico parmuychico"
										>
											<div className="tchica2">
												Cerrar
											</div>
										</Button>
									</ModalFooter>
								</Modal>
							</div>
						</Col>
					</Row>
				</div>
			</Frame>
		);
	}
}

export default Seccion5;
