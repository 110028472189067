import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import logo from "../imgs/logoAzul.svg";

class Seccion6 extends PureComponent {
	state = {
		ver: false
	};

	ver() {
		this.setState({ ver: !this.state.ver });
	}

	render() {
		return (
			<React.Fragment>
				<Row className="parenorme">
					<Col
						xs={{ size: 10, offset: 1 }}
						md={{ size: 8, offset: 2 }}
					>
						<div className="pgrande centro">
							<img
								src={logo}
								alt="logo"
								style={{ height: 130 }}
							/>
						</div>
						<div className="tmuygrande wbold centro">
							Cuestionario basado en la Encuesta Nacional de
							Gastos de los Hogares en Gasto del INEGI
						</div>{" "}
						<div className="wbold justificado parmuychico">
							El presente cuestionario es para medir el impacto
							del “MODELO” en las personas participantes en los
							niveles de bienestar subjetivo, salud, economía y
							emprendimiento, por lo que agradecemos su valiosa
							participación reiterándole que la información será
							manejada con ABSOLUTA CONFIDENCIALIDAD y sólo se
							utilizará con fines estadísticos.
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="centro parmuygrande">
						<iframe
							src="https://docs.google.com/forms/d/e/1FAIpQLSfgFwgUHxdlOtMPWzCLVaMc1qjtXuG55cUPalxM97jT4M1uug/viewform?embedded=true"
							width="640"
							height="9898"
							frameborder="0"
							marginheight="0"
							marginwidth="0"
						>
							Cargando…
						</iframe>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export default Seccion6;
