import React, { PureComponent } from "react";
import AreaEmpresas from "./AreaEmpresas";

class Empresas extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false
	};

	render() {
		return (
			<React.Fragment>
				<AreaEmpresas
					titulo="Empresas"
					img={
						"https://e-client.s3.amazonaws.com/imagenes-tu-mejor-yo/8.jpg"
					}
					subtitulo="Incrementa metas financieras y bienestar en tus equipos de trabajo"
					subsubtitulo="Diagnóstico con base a necesidades de la empresa"
					bullets={
						<ul className="pizmediano">
							<li className="parmuychico">
								Asegura el logro de objetivos y metas
								financieras por empleado
							</li>
							<li className="parmuychico">
								Asesoría comercial, legal, fiscal y contable
							</li>
							<li className="parmuychico">
								Mejora la comunicación de tus equipos de trabajo
							</li>
							<li className="parmuychico">
								Incrementa el índice de bienestar de tu personal
							</li>
							<li className="parmuychico">
								Pasa de ser una empresa familiar a un
								corporativo
							</li>
						</ul>
					}
				/>
			</React.Fragment>
		);
	}
}

export default Empresas;
